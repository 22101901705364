/**
* Created by hansxing on 2019/2/26.
*/
<style lang="scss" scoped>
.order-details {
  padding-bottom: 50px;

  .order-details_head {
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .details_head_bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .details_head_cont {
    margin: auto;
    color: #fff;
    z-index: 9;

    p {
      font-size: 18px;
    }
  }

  .order-details_sect {

  }

  .details_sect_num {
    font-size: 12px;
    color: #999999;
  }

  .details_sect_price {
    font-size: 12px;

    h5 {
      font-size: 14px;

      span {
        color: #FF3B30;
      }
    }

  }

  .set-price {
    position: relative;
    font-size: 12px;
    margin-bottom: 10px;
    background: #fff;
    margin-top: -10px;
    padding: 10px 12px;

    &:before {
      content: "";
      display: block;
      height: 1px;
      background: #f8f8f8;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .details_sect_product {

  }

  .details_sect_more {
    text-align: center;
    margin: 20px 0;
    font-size: 12px;
    color: #999999;
  }

  .order-details_foot {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    height: 50px;
    display: flex;
    background-color: #fff;

    .mint-button {
      margin-top: 15px;
      width: 100%;
      height: 20px;
      border-radius: 0;
      border-right: 1px solid #e5e5e5;
      font-size: 14px;
      flex: 1;
      background-color: #fff;

      &:last-child {
        border: 0;
      }

      &:after {
        border: 0;
        background: transparent;
      }

      &.mint-button--default {
        box-shadow: none;
      }

      &.mint-button--normal {
        padding: 0;
      }
    }

    .foot-refuse-order {
      background: #fff;
      position: relative;
      color: #319BF7;

      &:before {
        content: "";
        display: block;
        height: 1px;
        background: #f8f8f8;
        position: absolute;
        right: 0;
        left: 0;
        top: 0;
      }
    }
  }

}

.bottom-btns {
  display: flex;

  a {
    color: #319bf7;
    margin-right: 10px;
    font-size: 14px;
  }
}
</style>
<style lang="scss">
.mint-msgbox-content {
  font-size: 14px;
}

.mint-msgbox-btns {
  height: 44px;
  line-height: 44px;
}

.message-btn {
  font-size: 17px;
}
</style>
<template>

  <div class="order-details com-bms-h5_section">
    <div class="order-details_head">
      <div class="details_head_bg">
        <img :src="orderInfoDto.orderStatus | orderStaListImage(userInfo.userType)">
      </div>
      <div class="details_head_cont">
        <p>{{ orderInfoDto.orderStatus | orderStaListDesc }}</p>
      </div>
    </div>
    <div class="order-details_sect">
      <div class="pad1012 details_sect_guide font_s14" v-if="driverGuiderDto">
        <p>司导：<span>{{ driverGuiderDto.guiderName }}</span></p>
        <p>司导手机号：<span>{{ driverGuiderDto.guiderMobile }}</span></p>
        <p>车牌号：<span>{{ driverGuiderDto.carNumber }}</span></p>
      </div>
      <div class="pad1012 details_sect_guide font_s14" v-if="!driverGuiderDto">
        <p>暂无司导信息</p>
      </div>
      <div class="pad1012 details_sect_info font_s14">
        <!--                <p>订单来源：{{sourceTypeList[orderInfoDto.sourceType - 1] ? sourceTypeList[orderInfoDto.sourceType - 1].name : '-'}}</p>-->
        <p>
          订单来源：{{ sourceTypeList[orderInfoDto.sourceType] ? sourceTypeList[orderInfoDto.sourceType].name : '-' }}</p>
        <p v-if="thirdOrderInfoDto">源平台订单号：{{ thirdOrderInfoDto.thirdOrderId || '-' }}
          <span style="background: #f8f8f8;border-radius: 2px;display: inline-block;padding: 1px 4px;color: #319BF7"
                v-clipboard:copy="thirdOrderInfoDto.thirdOrderId"
                v-clipboard:success="handleOrderIdcopy"
                v-clipboard:error="handleOrderIdOnError">复制</span>
        </p>
        <p>订单编号：{{ orderInfoDto.orderNo }}</p>
        <p>下单时间：<span>{{ orderInfoDto.createTime | dateCus('yyyy-MM-dd hh:mm') }}</span>
        </p>
      </div>
      <div class="pad1012 details_sect_info font_s14">
        <p>出发地：{{ orderDetailDto.productStartCityName }} - 目的地：{{ orderDetailDto.productDestCityName }}</p>
        <p v-if="orderInfoDto.tripStartTime">
          出行时间：<span>{{ orderInfoDto.tripStartTime | dateCus('yyyy-MM-dd hh:mm') }}</span>
          <span
              v-if="orderInfoDto.tripStartTime != orderInfoDto.tripEndTime"> - {{ orderInfoDto.tripEndTime | dateCus('yyyy-MM-dd hh:mm') }}</span>
        </p>
        <p v-if="orderInfoDto.departure">出发地址：{{ orderInfoDto.departure }}</p>
        <p v-if="orderInfoDto.destination">送达地址：{{ orderInfoDto.destination }}</p>
        <p>出行人：{{ orderInfoDto.tripUserName }}，{{ orderInfoDto.tripUserMobile }}，{{ orderInfoDto.tripUserEmail }}</p>
        <p>出行人数：{{ orderInfoDto.playPersonNumber || 1 }}人</p>
        <p>出行天数：{{ orderInfoDto.tripDay || '1' }}人</p>
        <p v-if="orderInfoDto.flightNumber">航班号：{{ orderInfoDto.flightNumber }}</p>
        <p v-if="orderInfoDto.contactUserName">
          紧急联系人：{{ orderInfoDto.contactUserName }}，{{ orderInfoDto.contactUserMobile }}</p>
        <p v-if="orderInfoDto.orderRemake">备注：{{ orderInfoDto.orderRemake }}</p>
        <!--<p>合同：<span style="color: #000038;">《……合同》</span></p>-->
      </div>
      <div class="pad1012 details_sect_product font_s14">
        <h5 class="font_s14">
          产品形态：<span>{{ orderDetailDto.productForm | productModalityDesc }}</span>
        </h5>
        <p>产品：[ID:{{ orderDetailDto.productNo }}]{{ orderDetailDto.productName }}</p>
        <p>资源：[ID:{{ orderDetailDto.resourceNo }}]{{ orderDetailDto.resourceName }}</p>
      </div>
      <div class="pad1012 details_sect_price">
        <div>
          <p>运营商：{{ orderInfoDto.operatorName }}</p>
          <div class="details_sect_num">
            <p>销售单价：{{
                ((orderInfoDto.sourceType == 9 || orderInfoDto.sourceType == 10) ?
                    orderDetailDto.inventoryCustomerPrice : orderDetailDto.inventoryPeerPrice) | currency
              }}</p>
            <p>销售数量：{{ orderDetailDto.resourceNumber || 0 }}
              <p>订单金额：{{ orderInfoDto.orderAmount | currency }}</p>
              <p>优惠：{{ orderInfoDto.discountsAmount | currency }}</p>
              <p>渠道：{{ orderInfoDto.channelAmount | currency }}</p>
              <p>已退金额：{{ orderInfoDto.refundAmount | currency }}</p>
          </div>
          <h5 class="font_s14">应收金额：<span>{{ orderInfoDto.receivableAmount | currency }}</span></h5>
          <p class="details_sect_num">实收金额：{{ orderInfoDto.paymentAmount | currency }}</p>
        </div>
        <p style="margin-top: 20px">供应商：{{ orderInfoDto.supplierName || '暂未分配供应商' }}</p>
        <div class="details_sect_num">
          <p>结算单价：{{ orderDetailDto.inventorySettlePrice | currency }}</p>
          <p>结算数量：{{ orderDetailDto.resourceNumber }}</p>
          <p>应付金额：{{ orderInfoDto.settleAmount | currency }}</p>
        </div>
        <h5>实付金额：<span>{{ orderInfoDto.actualSettleAmount | currency }}</span></h5>
        <h5 class="font_s14" style="margin-top: 20px" v-if="orderInfoDto.driverPrice">
          司机结算金额：<span>{{ orderInfoDto.driverPrice | currency }}</span></h5>
      </div>
      <div class="pad1012 bottom-btns">
        <a href="javascript:void(0)" @click="handelNext('orderLog')" class="link-btn">订单日志 》</a>
        <a href="javascript:void(0)" @click="handelNext('orderNotes')" class="link-btn">客服备注 》</a>
      </div>
      <!--<div class="pad1012 details_sect_num">-->
      <!--<p>订单编号：{{orderInfoDto.orderNo}}</p>-->
      <!--<p>创建时间：{{orderInfoDto.createTime | dateCus('yyyy-MM-dd hh:mm:ss') }} </p>-->
      <!--</div>-->

      <div class="details_sect_more">
        <p>更多订单信息请至电脑端查看</p>
      </div>
    </div>
    <div class="order-details_foot" v-if="orderInfoDto.orderStatus!=72">
      <mt-button @click="refuseOrder()"
                 v-if="((orderInfoDto.orderStatus==23||orderInfoDto.orderStatus==21)&&userInfo.userType!=1)||(orderInfoDto.orderStatus==22&&userInfo.userType==1)">
        拒绝订单
      </mt-button>
      <mt-button @click="confirmOrder()"
                 v-if="orderInfoDto.orderStatus==22&&userInfo.userType==1">
        确认订单
      </mt-button>
      <mt-button
          v-if="(orderInfoDto.orderStatus==31||orderInfoDto.orderStatus==41||orderInfoDto.orderStatus==42||orderInfoDto.orderStatus==43||orderInfoDto.orderStatus==51)&&(orderInfoDto.resourceUseCar==0||userInfo.userType!=1)"
          @click="restoreNewOrder()"
      >派单重置
      </mt-button>
      <mt-button @click="goGrab()"
                 v-if="orderInfoDto.orderStatus!=22 &&orderInfoDto.orderStatus<32">
        分派抢单
      </mt-button>
      <!--<mt-button type="primary" @click="confirmOrder()"-->
      <!--v-if="((orderInfoDto.orderStatus==21&&userInfo.userType==0)||orderInfoDto.orderStatus==31||orderInfoDto.orderStatus==23||orderInfoDto.orderStatus==41)&&userInfo.userType!=2">-->
      <!--分配司导-->
      <!--</mt-button>-->
      <mt-button
          v-if="(orderInfoDto.orderStatus==31||orderInfoDto.orderStatus==41)&&userInfo.userType!=1 && orderInfoDto.sourceType!= 2 &&orderInfoDto.sourceType!= 7"
          @click="cancelOrder()"
      >取消订单
      </mt-button>
      <mt-button @click="goAsDriver()"
                 v-if="((orderInfoDto.orderStatus==21&&userInfo.userType==0)||orderInfoDto.orderStatus==31||orderInfoDto.orderStatus==23||orderInfoDto.orderStatus==41)&&userInfo.userType!=2">
        {{ orderInfoDto.orderStatus == 41 ? '重新分配司导' : '分配司导' }}
      </mt-button>
      <mt-button v-if="(orderInfoDto.orderStatus==23||orderInfoDto.orderStatus==21)&&userInfo.userType!=1"
                 @click="distributionSupplier()">
        分配供应商
      </mt-button>
    </div>

  </div>

</template>

<script type="text/ecmascript-6">
import {MessageBox, Toast, Button} from 'mint-ui';

import orderServer from '@/urls/orderUrls'
import dataInfo from "@/data/orderData/data";
import {productFormList} from "@/data/index";

const getOrderStaList = (userType) => {
  userType = Number(userType)
  return [
    {
      name: '新订待分配', value: '21',
      image: require('../../../assets/orderDetails/daipaiche.jpg')
    },
    {
      name: userType !== 1 ? '待供应商确认' : '新订单待确认', value: '22',
      image: require('../../../assets/orderDetails/daikefuqueren.jpg')
    },
    {name: '供应商拒绝', value: '23', image: require('../../../assets/orderDetails/yijujue.jpg')},
    {name: '待分配司导', value: '31', image: require('../../../assets/orderDetails/daipaiche.jpg')},
    {name: '抢单中', value: '32', image: require('../../../assets/orderDetails/daipaiche.jpg')},
    {name: '待出行', value: '41', image: require('../../../assets/orderDetails/daipaiche.jpg')},
    {name: '进行中', value: '51', image: require('../../../assets/orderDetails/daipaiche.jpg')},
    {name: '已完成', value: '61', image: require('../../../assets/orderDetails/jiaoyichenggong.jpg')},
    {name: '取消中', value: '71', image: require('../../../assets/orderDetails/yiquxiao.jpg')},
    {name: '已取消', value: '72', image: require('../../../assets/orderDetails/yiquxiao.jpg')},
    {name: '用户申请取消', value: '1', image: require('../../../assets/orderDetails/yiquxiao.jpg')},
    {
      name: userType !== 1 ? '待供应商确认取消' : '取消待处理', value: '3',
      image: require('../../../assets/orderDetails/yiquxiao.jpg')
    },
    {name: '已取消', value: '2', image: require('../../../assets/orderDetails/yiquxiao.jpg')},
    {
      name: userType !== 1 ? '待供应商确认变更' : '订单变更待处理', value: '1',
      image: require('../../../assets/orderDetails/daipaiche.jpg')
    }
  ]
}


export default {
  //定义模版数据
  data() {
    let userInfo = this.$store.state.loginUserInfo;
    return {
      userInfo: userInfo,
      orderStaList: getOrderStaList(userInfo.userType),
      productType: [
        {  // 产品类型
          value: '1',
          text: '接送服务'
        },
        {
          value: '2',
          text: '旅游包车'
        },
        {
          value: '3',
          text: '旅游线路'
        }
      ],
      productModality: productFormList,
      orderInfoDto: {},
      thirdOrderInfoDto: null,
      driverGuiderDto: null,
      orderDetailDto: {},
      orderSuppliers: [],//订单所属供应上列表
      supplierSheetVisible: false,
      sourceTypeList: dataInfo.sourceTypeList,
    }
  },
  components: {
    MtButton: Button
  },
  filters: {
    orderStaListDesc(value, userType) {
      let ret = getOrderStaList(userType).filter((item) => item.value == value)
      if (ret.length > 0)
        return ret[0].name
      return '';
    },
    orderStaListImage(value, userType) {
      console.log(value, userType);
      let ret = getOrderStaList(userType).filter((item) => item.value == value)
      if (ret.length > 0)
        return ret[0].image
      return '';
    },
    productModalityDesc(value) {
      let ret = productFormList.filter((item) => item.value == value)
      if (ret.length > 0)
        return ret[0].text
      return ''
    }
  },
  //计算属性
  computed: {},
  activated() {
    this.getOrderDetail()
  },
  //定义事件方法
  methods: {
    async getOrderDetail() {
      let res = await this.http(`/galaxyOrder/getOrderDetail/${this.$route.params.orderId}`);
      if (res.success && res.data) {
        this.driverGuiderDto = res.data.distributionGuiderDto;  // 司导信息
        this.orderDetailDto = res.data.orderDetailDto;
        this.orderInfoDto = res.data.orderInfoDto;
        this.thirdOrderInfoDto = res.data.thirdOrderInfoDto;
        let departure = res.data.orderInfoDto.departure;
        this.orderInfoDto.departure = departure
        if (departure) {
          if ((departure.indexOf('title') != -1 || departure.indexOf('value') != -1)) {
            departure = JSON.parse(departure);
            this.orderInfoDto.departure = departure.title || departure.value;
          }
        }

        let destination = res.data.orderInfoDto.destination;
        this.orderInfoDto.destination = destination;
        if (destination && (destination.indexOf('title') != -1 || destination.indexOf('value') != -1)) {
          destination = JSON.parse(destination);
          this.orderInfoDto.destination = destination.title || destination.value
        }
      }
    },
    refuseOrder() {  // 拒绝订单
      MessageBox.confirm('', {
        title: '拒绝订单',
        message: '该产品资源无库存。将拒绝用户订单，确认继续?',
        confirmButtonClass: 'message-btn',
        cancelButtonClass: 'message-btn'
      }).then(() => {
        this.operatorRefuse()
      }).catch(() => {
      });
    },
    async operatorRefuse() {//运营商拒绝订单
      let form = {
        orderId: this.$route.params.orderId
      }
      let res = ''
      const userType = this.userInfo.userType;
      let nextStatus = 23;
      if (userType == 1)
        res = await this.http('/galaxyOrder/supplierRefuse', form, "POST", false);
      else {
        nextStatus = 72;
        res = await this.http('/galaxyOrder/operatorRefuse', form, "POST", false);
      }

      console.log(res);
      if (res.success) {
        Toast('拒绝成功');
        this.$store.dispatch('changeOrderStatus', {
          orderId: this.$route.params.orderId,
          orderStatus: nextStatus
        })
        this.$router.push({
          name: 'orderManage',
        });
      } else {
        Toast(res.errors[0].message)
      }
    },
    confirmOrder() {
      MessageBox.confirm('', {
        title: '确认订单',
        message: '即将接单，确认继续?',
        confirmButtonClass: 'message-btn',
        cancelButtonClass: 'message-btn'
      }).then(() => {
        this.operatorConfirm();
      }).catch(() => {

      });
    },
    async operatorConfirm() {//供应商确认订单
      let form = {
        orderId: this.$route.params.orderId
      }
      let res = await this.http('/galaxyOrder/supplierConfirm', form, "POST", false);
      console.log(res);
      if (res.success) {
        Toast('订单确认成功');
        this.$store.dispatch('changeOrderStatus', {
          orderId: this.$route.params.orderId,
          orderStatus: 31
        })
        this.$router.push({
          name: 'orderManage',
        });
      } else {
        Toast(res.errors[0].message)
      }
    },
    goAsDriver() {  // 分配司导
      let driverInfo = {};
      switch (this.orderInfoDto.orderStatus) {
        case 31:
          driverInfo = 0;
          break;
        case 41:
          driverInfo.guiderName = this.driverGuiderDto.guiderName;
          driverInfo.guiderMobile = this.driverGuiderDto.guiderMobile;
          driverInfo.carNumber = this.driverGuiderDto.carNumber;
          driverInfo.driverPrice = this.orderInfoDto.driverPrice;
      }
      console.log(this.orderInfoDto);
      this.$router.push({
        name: 'assignDriver',
        params: {
          orderId: this.$route.params.orderId,
          sourceType: this.orderInfoDto.sourceType,
          driverInfo: JSON.stringify(driverInfo)
        }
      });
    },
    //分配和重新分配供应商
    async distributionSupplier() {
      this.$router.push({
        name: 'distSupplier',
        params: {
          orderId: this.$route.params.orderId,
          operatorId: this.orderInfoDto.productOperatorId,
          orderStatus: this.orderInfoDto.orderStatus
        }
      });
//                this.orderSuppliers = [];
//                const ret = await orderServer.getSupplierInfoByOperatorId(this,
//                    {operatorId: this.orderInfoDto.productOperatorId})
//                if (ret.success && ret.data) {
//                    this.orderSuppliers = ret.data;
//                    this.orderSuppliers.forEach((item) => {
//                        item.name = item.supplierName;
//                        item.method = this.supplierItemSelected
//                    })
//                    this.supplierSheetVisible = true;
//                }
//                else
//                    Toast("抱歉，获取供应商列表失败")
    },
    //确定供应商
    async supplierItemSelected(e) {
      const ret = await MessageBox.confirm(`将订单分配给：${e.supplierName}`, "确认分配供应商信息");
      if (ret != 'confirm')
        return;
      let form = {
        "orderId": this.$route.params.orderId,
        "supplierId": e.id
      }
      let res = null;
      if (this.orderInfoDto.orderStatus == 21)
        res = await orderServer.addSupplier(form);
      if (this.orderInfoDto.orderStatus == 23)
        res = await orderServer.reallocateSupplier(form);
      if (res.success) {
        this.$store.dispatch('changeOrderStatus', {
          orderId: this.$route.params.orderId,
          orderStatus: 22
        });
        Toast("供应商分配成功")
      } else
        Toast("供应商分配失败")
    },
    async restoreNewOrder() {  // 恢复新订,订单状态变更为新订待分配(拉回总部)
      const ret = await MessageBox.confirm(`将当前订单重置为新订待分配`, "派单重置");
      if (ret != 'confirm')
        return;
      let form = {
        id: this.$route.params.orderId
      }
      let res = await orderServer.getRestoreAssignSupplier(form)
      if (res.success) {
        this.$store.dispatch('changeOrderStatus', {
          orderId: this.$route.params.orderId,
          orderStatus: 21
        })
        Toast('已恢复新订');
      } else {
        Toast(res.errors[0].message)
      }
    },
    goGrab() {  // 分派订单
      this.$router.push({
        name: 'dispatchGrab',
        params: {
          orderId: this.$route.params.orderId,
          name: this.orderInfoDto.supplierName ? this.orderInfoDto.supplierName : this.orderInfoDto.operatorName
        }
      });
    },
    cancelOrder() {//取消订单

      this.$router.push({
        name: 'cancelOrder',
        params: {
          orderId: this.$route.params.orderId,
          refundAmount: this.orderInfoDto.refundAmount || 0,
          paymentAmount: this.orderInfoDto.paymentAmount,
          actualSettleAmount: this.orderInfoDto.actualSettleAmount
        }
      });
    },
    handleOrderIdcopy(e) {
      Toast("复制成功")

    }, // 源订单号复制
    handleOrderIdOnError() {
      Toast("复制失败")
    },
    handelNext(name) {
      this.$router.push({
        name,
        params: {
          orderId: this.orderInfoDto.id,
          orderNo: this.orderInfoDto.orderNo
        }
      })
    }
  },
  //监听模版变量
  watch: {}

}
</script>
